/* @v1.0 init */
import Logo from 'components/common/Logo';
import { Modal, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SearchBox from 'components/common/SearchBox';
import Button from 'components/base/Button';
import ThemeToggler from 'components/common/ThemeToggler';
import classNames from 'classnames';
import { Children, useEffect, useRef, useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import { hasSession } from 'helpers/utils';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/Anubis/anubis.png';
import { BotService } from 'service/botService';
import { faPaperPlane, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCart } from 'components/CartContext';

type AvatarStatus = 'online' | 'offline' | 'do-not-disturb' | 'away';

const NavItem = ({
  label,
  url,
  isLast,
  onClick
}: {
  label: string;
  url: string;
  isLast?: boolean;
  onClick?: () => void;
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const element = document.querySelector(url);
    if (element) {
      // Get the navbar height for offset calculation
      const navbar = document.querySelector('.landing-navbar');
      const navbarHeight = navbar ? navbar.getBoundingClientRect().height : 0;
      
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    if (onClick) onClick();
  };

  return (
    <Nav.Item
      as="li"
      className={classNames({
        'border-bottom border-translucent border-bottom-lg-0': !isLast
      })}
    >
      <Nav.Link onClick={handleClick} className="lh-1 py-0 fs-9 fw-bold py-3">
        {label}
      </Nav.Link>
    </Nav.Item>
  );
};

const DefaultLandingNavbar = ({ className }: { className?: string }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [botStatus, setBotStatus] = useState<AvatarStatus>('do-not-disturb');
  const [expanded, setExpanded] = useState(false);
  
  const { totalItems } = useCart(); // retorna el numero de objetos

  const fetchBotStatus = async () => {
    const status = await BotService.getStatus();
    setBotStatus(status.status === 'Available' ? 'online' : 'do-not-disturb');
  };

  useEffect(() => {
    fetchBotStatus();
    const intervalId = setInterval(fetchBotStatus, 30000);

    const toggleShadowClass = () => {
      if (window.scrollY > 300) {
        containerRef.current?.classList.add('navbar-shadow');
      } else {
        containerRef.current?.classList.remove('navbar-shadow');
      }
    };

    document.addEventListener('scroll', () => toggleShadowClass());

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('scroll', toggleShadowClass);
    };
  }, []);

  const handleNavItemClick = () => {
    if (window.innerWidth < 992) { // Bootstrap lg breakpoint
      setExpanded(false);
    }
  };

  return (
    <>
      <div
        className={classNames(
          className,
          'bg-body-emphasis sticky-top landing-navbar'
        )}
        ref={containerRef}
      >
        <Navbar 
          className="px-3 px-lg-7 px-xxl-3 container-small" 
          expand="lg"
          expanded={expanded}
          onToggle={(expanded) => setExpanded(expanded)}
        >
          <Navbar.Brand
            as={Link}
            to=""
            className="text-decoration-none flex-1 flex-lg-grow-0"
          >
            <Logo />
          </Navbar.Brand>
          <ThemeToggler className="mx-2 d-lg-none" />
          <Navbar.Toggle aria-controls="basic-navbar-nav" /> {/*}
      style={{
        border: 'none',            // Remove button border
        outline: 'none',           // Remove outline
        boxShadow: 'none',         // Remove box-shadow
        padding: 0,                // Remove any default padding
      }}>     
    <FeatherIcon icon="more-horizontal" size={32} style={{
            outline: 'none',         // Ensure the icon itself has no outline
            boxShadow: 'none',       // Remove any shadow applied to the icon
            padding: '0',            // Remove any padding if applied
          }} /> </Navbar.Toggle> */}
          <Navbar.Collapse>
            <div className='border-bottom border-translucent d-lg-none mb2'>
              <SearchBox
                placeholder='Search'
                className='w-100'
                inputClassName='rounded-pill my-4'
              />
            </div>

            <Nav className="me-auto mb-10 mb-lg-0" as="ul">
              <NavItem label={`${process.env.REACT_APP_NAME}`} url="#home" onClick={handleNavItemClick} />
              {/*<NavItem label="Features" url="#features" onClick={handleNavItemClick} />*/}
              <NavItem label="Enterprise Solutions" url="#enterprise" onClick={handleNavItemClick} />
              <NavItem label="Pricing" url="#pricing" onClick={handleNavItemClick} />
              <NavItem label="Partners" url="#partners" onClick={handleNavItemClick} />
              {/*<NavItem label="What's New" url="#whatsnew" onClick={handleNavItemClick} />*/}
              <NavItem label="Get in Touch" url="#support" isLast onClick={handleNavItemClick} />
            </Nav>
          
            <div className="d-grid d-lg-flex gap-4 align-items-center">
                <ThemeToggler className='d-none d-lg-block' />
                <Button
                  className='p-0 text-body-tertiary text-body-emphasis-hover d-none d-lg-inline lh-sm'
                  onClick={() => setOpenSearchModal(!openSearchModal)}
                >
                  <FeatherIcon icon="search" size={20} />
                </Button>
              {hasSession() ? (
                <div className="mb-0 text-body-tertiary" style={{position: "fixed", bottom: 10, right: 10}}>
                  <Link
                    to="/anubis"
                    className="btn btn-phoenix-primary"
                    title={botStatus === 'online'
                      ? "I'm online and ready to assist you!"
                      : "I'm currently offline. Please check back later."}
                  >
                    <Avatar
                      src={avatar}
                      size="3xl"
                      status={botStatus}
                      className="mx-auto"
                    /> Back to {process.env.REACT_APP_NAME}
                  </Link>
                </div>
              ) : (
                <>
                  <Link
                    to="/pages/authentication/split/Sign-In"
                    className="order-lg-1 btn btn-outline-success"
                  >
                    Sign in
                  </Link>
                </>
              )}
              {/* */}

          <Link to="/cart" className="position-relative">
                <FontAwesomeIcon icon={faShoppingCart} size="xl" className="text-xl" />
                {totalItems > 0 && (
                  <span
                    className="position-absolute top-0 start-10 translate-middle badge rounded-pill bg-danger"
                    style={{ fontSize: '0.65rem', padding: '0.25rem 0.4rem' }}
                  >
                    {totalItems}
                  </span>
                )}
              </Link>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            size="lg"
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DefaultLandingNavbar;