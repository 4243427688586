import { Col, Row } from 'react-bootstrap';
import logo from 'assets/img/icons/footer.png';

const Footer = () => {
  return (
    <section 
      data-bs-theme="light" 
      className="bg-dark dark__bg-gray-1000" 
      style={{ paddingTop: '10px', paddingBottom: '10px' }} // Adjust padding here
    >
      <div className="container-small px-lg-7 px-xxl-3 ">
        <Row className="gx-xxl-8 gy-5 align-items-center justify-content-center">
          <img 
            src={logo} 
            alt="Footer Logo" 
            className="img-fluid justify-content-center" 
            style={{ width: '70%', height: 'auto' }} 
          />
        </Row>
      </div>
    </section>
  );
};

export default Footer;
