import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import Scrollbar from 'components/base/Scrollbar';
import QuantityButtons from 'components/common/QuantityButtons';
import { CartItemType } from 'data/e-commerce/products';
import { currencyFormat } from 'helpers/utils';
import { useMemo, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CartItem, useCart } from 'components/CartContext';

interface EcomCartTableProps {
  products: CartItemType[];
}

const EcomCartTable = ({ products: initialProducts }: EcomCartTableProps) => {
  const { cart, updateCartQuantity, removeFromCart, totalPrice } = useCart();

  return (
    <Scrollbar autoHeight autoHeightMax="100%" className="table-scrollbar">
      <Table className="phoenix-table fs-9 mb-0 border-top border-translucent">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col" style={{ minWidth: 250 }}>
              PRODUCTS
            </th>
            <th className="text-end" scope="col" style={{ width: 300 }}>
              PRICE
            </th>
            <th className="ps-5" scope="col" style={{ width: 200 }}>
              Monthly
            </th>
            <th className="text-end" scope="col" style={{ width: 250 }}>
              TOTAL
            </th>
            <th className="text-end pe-0" scope="col" />
          </tr>
        </thead>
        <tbody className="list" id="cart-table-body">
          {cart.map((product, index) => (
            <EcomCartTableRow
              key={index}
              product={product}
              updateQuantity={updateCartQuantity}
              removeProduct={removeFromCart}
            />
          ))}

          <tr className="cart-table-row">
            <td
              className="text-body-emphasis fw-semibold ps-0 fs-8"
              colSpan={4}
            >
              Items subtotal :
            </td>
            <td className="text-body-emphasis fw-bold text-end fs-8">
              {currencyFormat(totalPrice)}
            </td>
            <td />
          </tr>
        </tbody>
      </Table>
    </Scrollbar>
  );
};

interface EcomCartTableRowProps {
  product: CartItem;
  updateQuantity: (category: string, quantity: number) => void;
  removeProduct: (category: string) => void;
}

const EcomCartTableRow = ({
  product,
  updateQuantity,
  removeProduct,
}: EcomCartTableRowProps) => {
  const [quantity, setQuantity] = useState(product.quantity); 

  const total = useMemo(() => {
    return product.price * quantity;
  }, [product.price, quantity]);

  useEffect(() => {
    updateQuantity(product.category, quantity);
  }, [quantity, product.category]);

  return (
    <tr className="cart-table-row">
      <td className="py-0">
        <div className="border border-translucent rounded-2">
          { /* <img src={product.image} alt={product.category} width={53} /> */ }
        </div>
      </td>
      <td>
        <Link className="fw-semibold line-clamp-2" to="#!">
          {product.category}
        </Link>
      </td>
      <td className="fw-semibold text-end">{currencyFormat(product.price)}</td>
      <td className="fs-8 ps-5">
        <QuantityButtons
          type="secondary"
          quantity={quantity}
          setQuantity={setQuantity}
        />
      </td>
      <td className="fw-bold text-body-highlight text-end">
        {currencyFormat(total)}
      </td>
      <td className="text-end ps-3">
        <Button
          size="sm"
          variant="link"
          className="text-body-quaternary text-body-tertiary-hover me-2"
          onClick={() => removeProduct(product.category)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

export default EcomCartTable;