import Logo from 'components/common/Logo';
import { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface AuthSplitLayoutProps {
  logo?: boolean;
  bg: string;
  bgVideo?: string; // Optional video background
}

const AuthSplitLayout = ({
  logo = true,
  bg,
  bgVideo, // Add bgVideo prop
  children
}: PropsWithChildren<AuthSplitLayoutProps>) => {
  return (
    <Row className="vh-100 g-0">
      <Col lg={6} className="position-relative d-none d-lg-block">
        {/* Conditionally render the video or background image */}
        {bgVideo ? (
          <video
            autoPlay
            playsInline
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          >
            <source src={bgVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div
  className="bg-holder"
  style={{
    backgroundImage: `url(${bg})`,
    width: '100%', // Set the width to 40%
    height: '100%', // Set the height to 40%
    backgroundSize: '79%',
    backgroundPosition: 'center',
    margin: 'auto', // Center horizontally
    position: 'absolute', // Enable centering both vertically and horizontally
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Adjust for true centering
  }}
></div>
        )}
      </Col>
      <Col lg={6}>
        <Row className="flex-center h-100 g-0 px-4 px-sm-0">
          <Col sm={6} lg={7} xl={6}>
            {logo && (
              <div className="text-center">
                <Link to="/" className="d-inline-block text-decoration-none">
                  <Logo
                    text={false}
                    width={350}
                    className="fw-bolder fs-5 d-inline-block"
                    
                  />
                </Link>
              </div>
            )}
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AuthSplitLayout;

