  import AppProvider from 'providers/AppProvider';
  import React from 'react';
  import ReactDOM from 'react-dom/client';
  import BreakpointsProvider from 'providers/BreakpointsProvider';
  import SettingsPanelProvider from 'providers/SettingsPanelProvider';
  import { RouterProvider } from 'react-router-dom';
  import { router } from 'Routes';
  import HazbotWidgetProvider from 'providers/HazbotWidgetProvider';
  import ChatWidgetProvider from 'providers/ChatWidgetProvider';

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <AppProvider>
        <SettingsPanelProvider>
          <HazbotWidgetProvider>
            {/*<ChatWidgetProvider>*/}
              <BreakpointsProvider>
                <RouterProvider router={router} />
              </BreakpointsProvider>
            {/*</ChatWidgetProvider>*/}
          </HazbotWidgetProvider>
        </SettingsPanelProvider>
      </AppProvider>
    </React.StrictMode>
  );
