import React from "react";
import { Modal } from "react-bootstrap";
import Button from "components/base/Button";

interface SuccessModalProps {
  show: boolean;
  onHide: () => void;
  message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ show, onHide, message }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Success</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;