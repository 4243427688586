import {
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UilEnvelope, UilMapMarker, UilPhone } from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

const HelpCenter = () => {
  return (
    <>
      <h3 className="mb-3">Stay connected</h3>
      <p className="mb-5">
      Stay connected with {process.env.REACT_APP_NAME}! We’d love to hear your feedback and learn what features you'd like to see next.
      </p>
      <div className="d-flex flex-column align-items-center align-items-md-start gap-4">
        <div className="d-flex flex-column flex-md-row gap-2 align-items-center">
          <div className="icon-wrapper shadow-info">
            <UilPhone
              size={40}
              className="text-primary fs-4 z-1 ms-2"
              data-bs-theme="light"
            />
          </div>
          <div className="flex-1 ms-3">
            <Link to="tel:+18329660445" className="link-900">
              +1 (832) 966-0445
            </Link>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row gap-2 align-items-center">
          <div className="icon-wrapper shadow-info">
            <UilEnvelope
              size={40}
              className="text-primary z-1 ms-2"
              data-bs-theme="light"
            />
          </div>
          <div className="flex-1 ms-3">
            <Link
              to="mailto:support@hazbot-ai.com"
              className="fw-semibold text-body"
            >
              support@anubisai.net
            </Link>
          </div>
        </div>
        <div className="mb-6 d-flex flex-column flex-md-row gap-2 align-items-center">
          <div className="icon-wrapper shadow-info">
            <UilMapMarker
              size={40}
              className="text-primary z-1 ms-2"
              data-bs-theme="light"
            />
          </div>
          <div className="flex-1 ms-3">
            <div className="fw-semibold text-body">
              <b>Headquarters</b> <br></br>
              Creatimus LLC.<br></br>
              United States - Houston, TX <br></br><br></br>
              <b>LATAM Authorized Agent</b><br></br>
              {process.env.REACT_APP_DEV} Group <br></br>
              Santiago, Chile. </div>
          </div>
        </div>
        <div className="d-flex gap-3">
          <a href="https://www.creatimus.com">
            <FontAwesomeIcon icon={faLinkedinIn} className="text-primary fs-6" />
          </a>
          <a href="https://www.creatimus.com">
            <FontAwesomeIcon icon={faTwitter} className="text-primary fs-6" />
          </a>
        </div>
      </div>
    </>
  );
};

export default HelpCenter;
