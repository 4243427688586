import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bg from 'assets/img/bg/Avatar.png';
import SignInForm from 'components/modules/auth/SignInForm';
import { useNavigate } from 'react-router-dom';
import { getHatateToken } from 'helpers/utils';
import { useEffect } from 'react';

const SignIn = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = getHatateToken();
    if (token) {
      navigate('/'); // Token exists, redirect to home
    }
  }, [navigate]);

  return (
    <AuthSplitLayout bg={bg}>
      <SignInForm layout="split" />
    </AuthSplitLayout>
  );
};

export default SignIn;
