import { Conversation, Message, User } from 'data/hazbot/chat';
import avatar from 'assets/img/Anubis/anubis.png';

export const adaptChatSessionsToConversations = (chatSessions: any[]): Conversation[] => {
  return chatSessions.map((chatSession, sessionIndex) => {
    const user: User = {
      id: sessionIndex + 1,
      avatar: avatar,
      status: 'online',
      //name: chatSession.chatName || `User ${chatSession.chatId.slice(-4)}`,
      name: 'Chat sin título'
    };

    const messages: Message[] = chatSession.prompts.map((prompt: any, index: any) => ({
      id: index + 1,
      type: prompt.role === 'user' ? 'sent' : 'received',
      message: prompt.content,
      time: prompt.time,
      readAt: null,
      attachments: undefined,
      seen: true, 
    })).sort((a: any, b: any) => {
        return Date.parse(a.time) - Date.parse(b.time);
    });

    return {
      id: chatSession.chatId, 
      user,
      messages,
      unreadMessages: 0,
    };
  });
};
