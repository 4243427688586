import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useCart } from 'components/CartContext';
import OrderSummaryDetails from 'components/common/OrderSummaryDetails';
import { currencyFormat, getHatateToken } from 'helpers/utils';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
  
const EcomCartSummaryCard = () => {
  const { totalPrice } = useCart();
  const finalTotal = totalPrice * 1.19;
  const navigate = useNavigate();

  const handleProceedToCheckout = () => {
    navigate('/checkout', {
      state: { 
        finalTotal,
        originalTotal: totalPrice 
      } 
    });
  };

  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <h3 className="mb-0">Summary</h3>
        </div>

        <OrderSummaryDetails />

        <div className="d-flex justify-content-between border-y border-dashed border-translucent py-3 mb-4">
          <h4 className="mb-0">Total :</h4>
          <h4 className="mb-">
            {currencyFormat(finalTotal, { minimumFractionDigits: 2})}
          </h4>
        </div>

        <Button
          className="w-100"
          variant="primary"
          disabled={finalTotal <= 0}
          onClick={handleProceedToCheckout}
          endIcon={
            <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs-10" />
          }
        >
          {finalTotal <= 0 ? 'Cart is empty' : 'Proceed'}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default EcomCartSummaryCard;
/* @v0.1 init 
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useCart } from 'components/CartContext';
import OrderSummaryDetails from 'components/common/OrderSummaryDetails';
import { currencyFormat } from 'helpers/utils';
import React from 'react';
import { Card, Form, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
  
const EcomCartSummaryCard = () => {
  const { totalPrice } = useCart();
  const finalTotal = totalPrice * 1.19;

  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <h3 className="mb-0">Summary</h3>
        </div>

        <OrderSummaryDetails />

        <div className="d-flex justify-content-between border-y border-dashed border-translucent py-3 mb-4">
          <h4 className="mb-0">Total :</h4>
          <h4 className="mb-">
            {currencyFormat(finalTotal, { minimumFractionDigits: 2})}
          </h4>
        </div>

        <Button
          className="w-100"
          variant="primary"
          disabled={finalTotal <= 0}
          endIcon={
            <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs-10" />
          }
        >
          {finalTotal <= 0 ? 'Cart is empty' : 'Proceed to register'}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default EcomCartSummaryCard; */