import ChatHomepageCard from 'components/cards/ConversationsHomepageCard';
import ChatSidebar from 'components/modules/AnubisConversations/ChatSidebar';

const ChatHomepage = () => {
  return (
    <>
      <ChatSidebar />
      <ChatHomepageCard />
    </>
  );
};

export default ChatHomepage;
