import React, { useEffect, useRef, useState } from 'react';
import Avatar from 'components/base/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { suggestions } from 'data/hazbot/chat';
import Button from 'components/base/Button';
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import Message from 'components/modules/chat/message';
import avatar from 'assets/img/team/40x40/avatar1.png';
import { BotService } from 'service/botService';

// Define the correct type for the Avatar status
type AvatarStatus = 'online' | 'offline' | 'do-not-disturb' | 'away';

const HazbotConversation = () => {
  const { conversation, sentMessage } = useChatWidgetContext();
  const messageEndRef = useRef<null | HTMLSpanElement>(null);
  const [botStatus, setBotStatus] = useState<AvatarStatus>('do-not-disturb');

  useEffect(() => {
    messageEndRef.current?.scrollIntoView();
  }, [conversation]);

  useEffect(() => {
    const fetchBotStatus = async () => {
      const status = await BotService.getStatus();
      setBotStatus(status.status === 'Available' ? 'online' : 'do-not-disturb');
    };

    fetchBotStatus();
    const intervalId = setInterval(fetchBotStatus, 30000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="text-center mt-auto">
        <Avatar
          src={avatar}
          size="3xl"
          status={botStatus}
          className="mx-auto"
        />
        <h5 className="mt-2 mb-3">Hazbot</h5>
        <p className="text-center text-body-emphasis mb-0">
          {botStatus === 'online' 
            ? "I'm online and ready to assist you!"
            : "I'm currently offline. Please check back later."}
        </p>
      </div>
      {!conversation.messages.length && (
        <div className="text-end mt-6">
          <div className="d-flex flex-column align-items-end gap-2">
            {suggestions.map((message, index) => (
              <Button
                key={message}
                onClick={() => sentMessage({ message })}
                className={classNames(
                  'd-inline-flex align-items-center text-body-emphasis bg-body-hover rounded-pill border border-primary py-2 ps-4 pe-3 lh-base w-100',
                )}
              >
                <p className="mb-0 fw-semibold fs-9 text-truncate">{message}</p>
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  className="text-primary fs-9 ms-3"
                />
              </Button>
            ))}
          </div>
        </div>
      )}
      {conversation.messages.length > 0 && (
        <>
          <hr className="my-4 border-dashed border-top" />
          <div className="d-flex flex-column gap-2">
            {conversation.messages.map(message => (
              <Message
                message={message}
                user={conversation.user}
                key={message.id}
                showActions={false}
              />
            ))}
            <span ref={messageEndRef} />
          </div>
        </>
      )}
    </>
  );
};

export default HazbotConversation;
