import React from "react";
import { Modal } from "react-bootstrap";
import Button from "components/base/Button";

interface ErrorModalProps {
  show: boolean;
  onHide: () => void;
  message: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ show, onHide, message }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;