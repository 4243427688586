import React, { useEffect, useState, useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { UserService } from 'service/userService';
import { useAppContext } from 'providers/AppProvider';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import { IUser } from 'data/models/user/IUser';
import useToggleStyle from 'hooks/useToggleStyle';
import { UserContext } from 'layouts/UserContext';
import { CartProvider } from 'components/CartContext';

const App: React.FC = () => {
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();

  const {
    config: { theme },
  } = useAppContext();

  const [user, setUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUser = useCallback(async () => {
    try {
      const fetchedUser = await UserService.getUser();
      setUser(fetchedUser);
    } catch (err) {
      UserService.logout();
      setUser(null);
      setError('Failed to fetch user data');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        error,
        refetchUser: fetchUser,
        isAuthenticated: !!user
      }}
    >
      <CartProvider>
        {!isStylesheetLoaded ? (
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: theme === 'dark' ? '#000' : '#fff',
            }}
          />
        ) : (
          <>
            <Outlet />
            {/*<SettingsToggle /> */}
            {/*<SettingsPanel />*/}
          </>
        )}
      </CartProvider>
    </UserContext.Provider>
  );
};

export default App;