import { useEffect, useState } from 'react';
import ChatProvider from 'providers/ChatProvider';
import { Outlet } from 'react-router-dom';
import { Conversation } from 'data/hazbot/chat';
import { UserService } from 'service/userService';
import { adaptChatSessionsToConversations } from 'helpers/adapter';

const Chat = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchConversations = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await UserService.getChatSessionsPaginated();
        
        const adaptedConversations = adaptChatSessionsToConversations(response.data);

        setConversations(adaptedConversations);
      } catch (err: any) {
        console.error('Error fetching conversations:', err);
        setError('Failed to load conversations.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchConversations();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <ChatProvider conversations={conversations}>
      <div className="chat d-flex gap-3">
        <Outlet />
      </div>
    </ChatProvider>
  );
};

export default Chat;