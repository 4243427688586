import { getHatateToken } from "helpers/utils";
import { Navigate } from "react-router-dom";

export const AuthenticationRoute = ({ children }: any) => {
  if (getHatateToken() != '') {
    return <Navigate to="/anubis" replace />;
  }

  return children;
};
