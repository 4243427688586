import React from "react";
import { Modal } from "react-bootstrap";
import Button from "components/base/Button";

interface PrivacyModalProps {
  show: boolean;
  onHide: () => void;
  onExited: () => void;
}

const PrivacyModal: React.FC<PrivacyModalProps> = ({ show, onHide, onExited }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExited}
    >
      <Modal.Header closeButton>
        <Modal.Title>Privacy Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>Effective Date: November 1st, 2024</h3>
        <h3>Last Updated: October 24th, 2024</h3>
        <br></br>
        <p>
          This Privacy Policy explains how Creatimus LLC (“we,” “our,” or
          “us”) collects, uses, and protects your personal information when
          you use {process.env.REACT_APP_NAME} and its associated website. By
          accessing or using {process.env.REACT_APP_NAME}, you agree to the
          collection and use of information in accordance with this policy.
        </p>

        <h4>1. Information We Collect</h4>
        <p>
          We collect the following types of data to provide and improve{" "}
          {process.env.REACT_APP_NAME}’s services:
        </p>
        <h5>1.1 Personal Information</h5>
        <ul>
          <li>
            Name, email address, phone number (if provided for account setup
            or communication).
          </li>
          <li>
            Company or organization information (for business accounts).
          </li>
        </ul>

        <h5>1.2 Usage Data</h5>
        <ul>
          <li>
            IP address, browser type, device information, and access times
            when visiting the website.
          </li>
          <li>
            Data regarding interactions with {process.env.REACT_APP_NAME}'s
            features (e.g., inputs for hazard analysis or search queries).
          </li>
        </ul>

        <h5>1.3 Cookies and Tracking Technologies</h5>
        <p>
          We use cookies and similar technologies to enhance the user
          experience, track website traffic, and remember user preferences.
          You can manage your cookie preferences through your browser
          settings.
        </p>

        <h4>2. How We Use Your Information</h4>
        <p>We use the collected data to:</p>
        <ol>
          <li>
            Provide, operate, and improve {process.env.REACT_APP_NAME}’s
            services.
          </li>
          <li>Customize the user experience and develop new features.</li>
          <li>
            Communicate with users about updates, promotions, or technical
            support.
          </li>
          <li>
            Analyze website traffic and usage patterns to improve performance.
          </li>
          <li>
            Ensure compliance with legal obligations and enforce our Terms and
            Conditions.
          </li>
        </ol>

        <h4>3. Sharing Your Information</h4>
        <p>
          We do not sell or rent personal data to third parties. However, we
          may share your data in the following circumstances:
        </p>
        <ol>
          <li>
            With Service Providers: Third-party vendors that assist us with
            hosting, analytics, and support services.
          </li>
          <li>
            Legal Compliance: If required to do so by law or to respond to
            valid legal requests (e.g., subpoenas).
          </li>
          <li>
            Business Transfers: In case of a merger, sale, or transfer of our
            assets, your data may be transferred to the new entity.
          </li>
        </ol>

        <h4>4. Data Retention</h4>
        <p>
          We retain personal information for as long as necessary to provide
          services or as required by law. When no longer needed, data is
          securely deleted or anonymized.
        </p>

        <h4>5. Your Data Rights</h4>
        <p>Depending on your location, you may have the following rights:</p>
        <ol>
          <li>
            Access and Correction: Request access to the personal data we hold
            about you or correct inaccuracies.
          </li>
          <li>
            Data Deletion: Request deletion of your personal data, subject to
            legal or contractual obligations.
          </li>
          <li>
            Data Portability: Obtain a copy of your personal data in a
            portable format.
          </li>
          <li>
            Withdraw Consent: If you previously gave consent to data
            processing, you can withdraw it at any time.
          </li>
        </ol>
        <p>
          To exercise your rights, please contact us at [Insert Email
          Address].
        </p>

        <h4>6. Security Measures</h4>
        <p>
          We implement technical and organizational measures to protect your
          data from unauthorized access, disclosure, or alteration. However,
          no system is entirely secure, and we cannot guarantee the absolute
          security of your information. If you become aware of any security
          breach, please notify us immediately at [Insert Email Address].
        </p>

        <h4>7. Children’s Privacy</h4>
        <p>
          {process.env.REACT_APP_NAME} is not intended for use by individuals
          under the age of 18. We do not knowingly collect personal
          information from children. If you believe we have collected data
          from a child, please contact us, and we will promptly delete the
          information.
        </p>

        <h4>8. International Data Transfers</h4>
        <p>
          If you are accessing {process.env.REACT_APP_NAME} from outside the
          United States, please note that your data may be transferred to and
          processed in the United States, where data protection laws may
          differ from those in your jurisdiction.
        </p>

        <h4>9. Third-Party Links and Services</h4>
        <p>
          Our website may contain links to third-party services or websites.
          We are not responsible for the privacy practices of these external
          services. Please review their privacy policies before interacting
          with them.
        </p>

        <h4>10. Changes to This Privacy Policy</h4>
        <p>
          We may update this Privacy Policy periodically. When changes are
          made, we will notify you by posting the updated policy on our
          website and updating the "Last Updated" date. Your continued use of{" "}
          {process.env.REACT_APP_NAME} after changes constitutes your
          acceptance of the revised policy.
        </p>

        <h4>11. Contact Us</h4>
        <p>
          If you have any questions about these Terms, please contact us at:
          <br />
          Creatimus LLC
          <br />
          support@creatimus.com
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          I Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyModal;