import React from "react";
import { Modal } from "react-bootstrap";
import Button from "components/base/Button";

interface TermsModalProps {
  show: boolean;
  onHide: () => void;
  onExited: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ show, onHide, onExited }) => {
  return (
    <Modal
    show={show}
    onHide={onHide}
    onExited={onExited}
    >
    <Modal.Header closeButton>
      <Modal.Title>Terms of Service</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h3>Effective Date: November 1st, 2024</h3>
      <h3>Last Updated: October 24th, 2024</h3>
      <br></br>
      <p>
        Welcome to {process.env.REACT_APP_NAME}. Please read these Terms and
        Conditions (“Terms”) carefully before using our website or services.
        By accessing or using {process.env.REACT_APP_NAME}’s website or
        software, you agree to be bound by these Terms. If you do not agree,
        please discontinue use immediately.
      </p>

      <h4>1. About {process.env.REACT_APP_NAME} and Ownership</h4>
      <p>
        {process.env.REACT_APP_NAME} and its associated website are products
        of Creatimus LLC, headquartered in Houston, Texas.{" "}
        {process.env.REACT_APP_NAME}’s technology infrastructure and AI
        services are developed in partnership with Linuxcorp USA. Linuxcorp
        Latam serves as the exclusive representative of Creatimus for{" "}
        {process.env.REACT_APP_NAME} in Chile and Latin America.
      </p>

      <h4>2. Eligibility and User Responsibility</h4>
      <ol>
        <li>
          Users must be at least 18 years old or have the consent of a legal
          guardian.
        </li>
        <li>
          Users agree to use {process.env.REACT_APP_NAME} only for lawful
          purposes related to chemical risk analysis, hazard management, or
          other intended functionalities.
        </li>
        <li>
          Users are responsible for maintaining the confidentiality of their
          account credentials and for all activities that occur under their
          account.
        </li>
      </ol>

      <h4>3. Advisory Nature of {process.env.REACT_APP_NAME}’s Services</h4>
      <p>
        {process.env.REACT_APP_NAME} is an advisory tool only and is not
        intended to replace the advice or judgment of certified
        professionals. All final decisions, actions, or inactions taken by
        users are their sole responsibility.
      </p>
      <ul>
        <li>
          Users must consult certified experts and professionals before
          using {process.env.REACT_APP_NAME}’s recommendations in real
          emergencies or critical situations.
        </li>
        <li>
          {process.env.REACT_APP_NAME} provides guidance based on its
          algorithms and data but does not guarantee accuracy, completeness,
          or suitability for any specific scenario.
        </li>
      </ul>

      <h4>4. Intellectual Property Rights</h4>
      <ol>
        <li>
          All content, code, data, and materials on{" "}
          {process.env.REACT_APP_NAME}’s platform, including logos,
          trademarks, software, and documentation, are the property of
          Creatimus LLC and its partners.
        </li>
        <li>
          Users are granted a limited, non-exclusive, non-transferable
          license to access and use {process.env.REACT_APP_NAME} solely for
          personal or business purposes. Unauthorized copying, modification,
          or distribution is strictly prohibited.
        </li>
      </ol>

      <h4>5. Acceptable Use Policy</h4>
      <ol>
        <li>
          Users agree not to:
          <ul>
            <li>Violate any applicable laws or regulations.</li>
            <li>
              Use {process.env.REACT_APP_NAME}’s services for unauthorized
              purposes or malicious activities.
            </li>
            <li>
              Interfere with the functionality or security of the website or
              any connected networks.
            </li>
          </ul>
        </li>
        <li>
          Creatimus LLC reserves the right to suspend or terminate accounts
          that engage in prohibited activities.
        </li>
      </ol>

      <h4>6. Disclaimer of Warranties</h4>
      <p>
        {process.env.REACT_APP_NAME}’s services are provided "as-is" without
        warranties of any kind, express or implied. The platform’s
        recommendations should not be used as a substitute for professional
        judgment in real-life emergencies.
      </p>
      <ul>
        <li>
          Users assume all risks related to the application of{" "}
          {process.env.REACT_APP_NAME}’s advice.
        </li>
        <li>
          Creatimus LLC and its partners do not guarantee that{" "}
          {process.env.REACT_APP_NAME}’s information is accurate,
          up-to-date, or applicable to every situation.
        </li>
      </ul>

      <h4>7. Limitation of Liability</h4>
      <ol>
        <li>
          Creatimus LLC, Linuxcorp USA, and Linuxcorp Latam are not liable
          for any damages resulting from decisions, actions, or outcomes
          based on {process.env.REACT_APP_NAME}’s advice or data.
        </li>
        <li>
          Users acknowledge that all responsibility for decisions rests with
          them, and the use of {process.env.REACT_APP_NAME}’s
          recommendations is at their own risk.
        </li>
        <li>
          {process.env.REACT_APP_NAME} does not provide emergency response
          services or crisis management. Users must always consult with
          appropriate experts or emergency responders before making
          decisions.
        </li>
      </ol>

      <h4>8. Third-Party Services and Links</h4>
      <p>
        {process.env.REACT_APP_NAME}’s platform may contain links to
        third-party websites or services. Creatimus LLC does not endorse or
        assume responsibility for these external resources and shall not be
        held liable for any interactions users have with them.
      </p>

      <h4>9. Privacy Policy</h4>
      <p>
        All data collected from users is governed by our Privacy Policy,
        which explains how we collect, store, and protect personal data. By
        using {process.env.REACT_APP_NAME}, users agree to the terms of the
        Privacy Policy.
      </p>

      <h4>10. User Data and Confidentiality</h4>
      <ol>
        <li>
          {process.env.REACT_APP_NAME} processes and stores information to
          provide its services. While we strive to secure data, we cannot
          guarantee the security of transmitted data.
        </li>
        <li>
          Users must notify us immediately if they suspect any unauthorized
          access to their account.
        </li>
      </ol>

      <h4>11. Modification of Terms</h4>
      <p>
        Creatimus LLC reserves the right to modify or update these Terms at
        any time. We will notify users of significant changes through the
        website or via email. Continued use of the services after changes
        constitutes acceptance of the revised Terms.
      </p>

      <h4>12. Termination</h4>
      <p>
        Creatimus LLC reserves the right to suspend or terminate access to{" "}
        {process.env.REACT_APP_NAME} services at any time without notice if
        users violate these Terms or engage in harmful activities.
      </p>

      <h4>13. Governing Law and Dispute Resolution</h4>
      <ol>
        <li>
          These Terms are governed by the laws of the State of Texas, USA,
          without regard to its conflict of law principles.
        </li>
        <li>
          Any disputes will be resolved through arbitration in Houston,
          Texas, unless otherwise required by law.
        </li>
      </ol>

      <h4>14. Contact Information</h4>
      <p>
        If you have any questions about these Terms, please contact us at:
      </p>
      <p>
        Creatimus LLC
        <br />
        support@creatimus.com
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button 
      variant="primary"
      onClick={onHide}>
        I Accept
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default TermsModal;