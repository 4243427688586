import axios from 'axios';
import { IMarketPaymentCart, IMarketPaymentProviderResponse } from 'data/models/payment/IPayment';
import { apiUrl, getHatateToken } from 'helpers/utils';

export const PaymentService = {
  createPaymentSession: async (cart: IMarketPaymentCart): Promise<IMarketPaymentProviderResponse> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };

    try {
      const response = await axios.post<IMarketPaymentProviderResponse>(`${apiUrl}stripe/create-checkout-session`, cart, { headers });

      return response.data;
    } catch (error: any) {
      console.error('Error procesando el pago:', error);
      throw error.response ? error.response.data.message : error.message;
    }
  },
};