/* @v1.0 queryform landing */
import React, { useState } from 'react';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';

interface FormData {
  name: string;
  email: string;
  message: string;
}

interface SubmitStatus {
  show: boolean;
  type: string;
  message: string;
}

const QueryForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>({ 
    show: false, 
    type: '', 
    message: '' 
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ show: false, type: '', message: '' });

    try {
      const response = await fetch('https://backend.anubisai.net/api/mail/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok) {
        setSubmitStatus({
          show: true,
          type: 'success',
          message: 'Message sent successfully!'
        });
        // Reset form
        setFormData({ name: '', email: '', message: '' });
      } else {
        throw new Error(data.message || 'Failed to send message');
      }
    } catch (err: unknown) {
      let errorMessage = 'An error occurred while sending the message';
      
      if (err instanceof Error) {
        errorMessage = err.message;
      } else if (typeof err === 'string') {
        errorMessage = err;
      } else if (err && typeof err === 'object' && 'message' in err) {
        errorMessage = String((err as { message: unknown }).message);
      }
      
      setSubmitStatus({
        show: true,
        type: 'error',
        message: errorMessage
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h3 className="mb-3">Drop us a line</h3>
      <p className="mb-7">
        We're here to help! Feel free to reach out with any questions or concerns, 
        and we'll get back to you right away!
      </p>

      {submitStatus.show && (
        <div className={`alert ${submitStatus.type === 'success' ? 'alert-success' : 'alert-danger'} mb-4`}>
          {submitStatus.message}
        </div>
      )}

      <Form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
        <Form.Control
          type="text"
          name="name"
          placeholder="Name"
          required
          value={formData.name}
          onChange={handleChange}
        />
        <Form.Control
          type="email"
          name="email"
          placeholder="Email"
          required
          value={formData.email}
          onChange={handleChange}
        />
        <Form.Control
          as="textarea"
          rows={6}
          name="message"
          placeholder="Message"
          required
          value={formData.message}
          onChange={handleChange}
        />
        <Button 
          type="submit" 
          variant="outline-primary"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Submit'}
        </Button>
      </Form>
    </>
  );
};

export default QueryForm;

/* @contact_form init 
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';

const QueryForm = () => {
  return (
    <>
      <h3 className="mb-3">Drop us a line</h3>
      <p className="mb-7">
      We're here to help! Feel free to reach out with any questions or concerns, and we’ll get back to you right away!
      </p>
      <Form className="d-flex flex-column gap-4">
        <Form.Control type="text" name="name" placeholder="Name" required />
        <Form.Control type="email" name="email" placeholder="Email" required />
        <Form.Control
          as="textarea"
          rows={6}
          name="message"
          placeholder="Message"
          required
        />
        <Button type="submit" variant="outline-primary">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default QueryForm; */
