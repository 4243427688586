import Section from 'components/base/Section';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import bgbiz from 'assets/img/bg/bg-23.png';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddressSection from 'components/modules/landing/alternate/AddressSection';
import Footer from 'components/modules/landing/alternate/Footer';
import { useCart } from 'components/CartContext';

const PaymentSuccess = () => {
  const { clearCart } = useCart();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    if (!sessionId) {
      navigate('../..');
    }

    clearCart();
  }, [sessionId, navigate]);

  return (
    <>
      <DefaultCartNavbar />
      <div 
        className="bg-body-emphasis dark__bg-gray-1200" 
        style={{
          backgroundImage: `url(${bgbiz})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          zIndex: 0,
          height: '100%',
          width: '100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Section small className="py-1">
        <h2 className="mb-6 mt-1text-center text-gradient-info mt-2 mb-4">Payment success <FontAwesomeIcon icon={faCheckCircle} size="lg" className="text-success text-xl" /></h2>
          <Row className="g-5 w-100">
            <Col xs={12} lg={12}>
              <p className="text-green-700 mb-2">
              Your payment has been processed successfully!
              </p>
              {sessionId && (
                <p className="text-sm text-green-600">
                  Session ID: {sessionId}
                </p>
              )}
            </Col>
          </Row>
        </Section>
        <AddressSection />
        <Footer />
      </div>
    </>
  );
};

export default PaymentSuccess;