import Section from 'components/base/Section';
import EcomCartSummaryCard from 'components/cards/EcomCartSummaryCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import AddressSection from 'components/modules/landing/alternate/AddressSection';
import Footer from 'components/modules/landing/alternate/Footer';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import EcomCartTable from 'components/tables/EcomCartTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import { cartItems } from 'data/e-commerce/products';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { Button, Card, Col, Row } from 'react-bootstrap';
import bgbiz from 'assets/img/bg/bg-23.png';
import { faCancel, faHome, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';


const Cart = () => {
  return (
      <>
    <DefaultCartNavbar />
    <div className="bg-body-emphasis dark__bg-gray-1200" style={{
      backgroundImage: `url(${bgbiz})`,
      backgroundSize: 'contain', // Change to 'contain' to fit within the section
      backgroundPosition: 'center',
      zIndex: 0,
      height: '100%',
      width: '100%',
      backgroundRepeat: 'no-repeat'
    }} >
            <Section small className="py-1">
              <h2 className="mb-6 mt-1text-center text-gradient-info mt-2 mb-4">Error - Payment Cancelled <FontAwesomeIcon icon={faCancel} size="lg" className="text-danger text-xl" /></h2>
              <Row className="g-5 w-100">
                <Col xs={12} lg={8}>
                  {/* <EcomCartTable products={cartItems} /> */}
                </Col>
                <Col xs={12} lg={8}>
                  {/*<EcomCartSummaryCard /> */}
                </Col>
              </Row>
              <div style={{border: '0px solid red'}} className='w-100 h-100'><Link to='../../'><FontAwesomeIcon icon={faHome} size="5x" /><br />Back to Home</Link></div>
            </Section>
          <AddressSection />
    <Footer />
    </div></>
  );
};

export default Cart;
