import React from 'react';
import { Col, Row } from 'react-bootstrap';
import bgbiz from 'assets/img/bg/bg-23.png';
import LightingIcon from 'assets/img/icons/lightning-speed.png';
import ShieldIcon from 'assets/img/icons/all-night.png';
import FeaturesIcon from 'assets/img/icons/editable-features.png';
import { useAppContext } from 'providers/AppProvider'; // Import the AppContext

// Import both images
import LightLinuxLogo from 'assets/img/Anubis/lightlinuxcorp.png';
import DarkLinuxLogo from 'assets/img/Anubis/linuxcorp.png';

const OneStopSolution = () => {
  const { config } = useAppContext(); // Access the config from AppContext
  const theme = config.theme; // Access theme from config
  const bluishColor = '#60d5ff'; // Set your desired bluish color here

  return (
    <section
      id="partners"
      className="alternate-landing-team"
      style={{ position: 'relative', overflow: 'hidden', padding: '60px 0' }}
    >
      {/* Container for Background Image and Overlay */}
      <div
        className="position-absolute w-10 h-10 start-0 end-0 top-0"
        style={{
          backgroundColor: 'rgba(15, 17, 26, 0.5)', // Semi-transparent overlay
          zIndex: 1,
        }}
      />
      <div
        className="bg-holder d-none d-xl-block"
        style={{
          backgroundImage: `url(${bgbiz})`,
          backgroundSize: 'contain', // Change to 'contain' to fit within the section
          backgroundPosition: 'center',
          zIndex: 0,
          height: '100%',
          width: '100%',
        }}
      />

      <div className="container-small px-lg-7 px-xxl-3" style={{ position: 'relative', zIndex: 1 }}>
        <Row className="text-center mb-1">
          <Col
            lg={12}
            className="text-center d-flex flex-column align-items-center justify-content-center"
            style={{ textAlign: 'justify' }}
          >
            <div className="text-center mb-1">
              <h1 className="text-gradient-info mt-4 mb-0">Partners</h1>
            </div>
            <h2 className="mb-2 lh-base">Our Network is Powered by</h2>
            <Col lg={5}>
              <a href="https://www.linuxcorp.cl" className="d-block">
                {/* Conditionally change the image based on the theme */}
                <img
                  src={theme === 'light' ? LightLinuxLogo : DarkLinuxLogo}
                  alt="Linuxcorp Logo"
                  className="img-fluid mb-3"
                  style={{ width: 'auto', height: 'auto' }}
                />
              </a>
            </Col>
            <br />
            {/* Description with Background Color */}
            <Col
              lg={9}
              className="mb-5"
              style={{
                textAlign: 'justify',
                backgroundColor: 'rgba(15, 17, 26, 0.3)',
                padding: '20px',
                borderRadius: '5px',
                border: '2px solid #60d5ff',
              }}
            >
              <b style={{ color: bluishColor }}>{process.env.REACT_APP_DEV}</b> serves as the backbone of our network infrastructure, providing essential services in network management, cyber security, and the development and deployment of information systems. Their expertise ensures that our AI operates on a robust and secure foundation.
            </Col>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg={4} className="d-flex flex-column align-items-center justify-content-between" style={{ minHeight: '300px' }}>
            <div className="mb-3 text-center">
              <img
                src={LightingIcon}
                alt="Linuxcorp Logo"
                className="img-fluid mb-3"
                style={{ width: 'auto', height: 'auto' }}
              />
              <h4>Network Infrastructure</h4>
              <p>Leveraging state-of-the-art neural network solutions with unmatched efficiency and precision.</p>
            </div>
          </Col>

          <Col lg={4} className="d-flex flex-column align-items-center justify-content-between" style={{ minHeight: '300px' }}>
            <div className="mb-3 text-center">
              <img
                src={ShieldIcon}
                alt="Linuxcorp Logo"
                className="img-fluid mb-3"
                style={{ width: 'auto', height: 'auto' }}
              />
              <h4>Cyber Security</h4>
              <p>24/7 real-time network monitoring and protection, ensuring uninterrupted security and performance.</p>
            </div>
          </Col>

          <Col lg={4} className="d-flex flex-column align-items-center justify-content-between" style={{ minHeight: '300px' }}>
            <div className="mb-3 text-center">
              <img
                src={FeaturesIcon}
                alt="Linuxcorp Logo"
                className="img-fluid mb-3"
                style={{ width: 'auto', height: 'auto' }}
              />
              <h4>Research & Development</h4>
              <p>Innovation and problem-solving are at the core of {process.env.REACT_APP_DEV}’s mission.</p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default OneStopSolution;
