import { FileAttachment } from 'components/common/AttachmentPreview';
import { time } from 'console';
import { chatBotInstance, Message as MessageType, Conversation } from 'data/hazbot/chat';
import dayjs from 'dayjs';
import { UserService } from 'service/userService';
import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback
} from 'react';

interface ChatWidgetProps {
  isOpenChat: boolean;
  conversation: Conversation;
  setConversation: Dispatch<SetStateAction<Conversation>>;
  setIsOpenChat: Dispatch<SetStateAction<boolean>>;
  selectedNeuroskill: string;
  setSelectedNeuroskill: Dispatch<SetStateAction<string>>;
  selectedModule: string;
  setSelectedModule: Dispatch<SetStateAction<string>>;
  sentMessage: ({
    message,
    attachments
  }: {
    message?: string;
    attachments?: { images?: string[]; file?: FileAttachment };
  }) => void;
  isProcessingInput: boolean;
}

export const ChatWidgetContext = createContext({} as ChatWidgetProps);

const HazbotWidgetProvider = ({ children }: PropsWithChildren) => {
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [conversation, setConversation] = useState(chatBotInstance);
  const [selectedNeuroskill, setSelectedNeuroskill] = useState('general_v1');
  const [selectedModule, setSelectedModule] = useState('general_general');
  const [isProcessingInput, setProcessingInput] = useState(false);  // tracking if the message is being processed

  const sentMessage = useCallback(
    async ({
      message,
      attachments
    }: {
      message?: string;
      attachments?: { images?: string[]; file?: FileAttachment };
    }) => {
      // Do not send message if it is being processed
      if (isProcessingInput) return;

      setProcessingInput(true);
      
      if (attachments && attachments.file) {
        console.log(attachments.file.file);
      }

      const newMessages = [
        ...conversation.messages,
        {
          id: Date.now(),
          type: 'sent',
          time: dayjs().format('MM/DD/YY HH:mm'),
          readAt: null,
          message,
          attachments
        } as MessageType
      ];
      const newConversation = { ...conversation, messages: newMessages };
      setConversation(newConversation);

      if (!message) {
        setProcessingInput(false);
        return;
      }

      try {
        const recentMessages = [...newConversation.messages]
        .reverse()
        .filter((msg, index) =>
          index < 6 && (msg.type === 'sent' || msg.type === 'received')
        )
        .reverse();

        const promptMessages = recentMessages.map(msg => {
          let content = msg.message || 'EMPTY PROMPT';
          
          if (msg.attachments?.file?.raw) {
            content = `${content}\n\nFile Content:\n${msg.attachments.file.raw}`;
          }
          
          return {
            role: msg.type === 'sent' ? 'user' : 'assistant' as 'user' | 'assistant',
            content,
            time: msg.time
          };
        });
        
        const botResponse = await UserService.getPromptResponse(
          chatBotInstance.id,
          selectedNeuroskill,
          selectedModule,
          promptMessages,
          attachments?.file
        );

        const newBotMessage: MessageType = {
          id: Date.now() + 1,
          type: 'received',
          time: dayjs().format('MM/DD/YY HH:mm'),
          readAt: null,
          message: botResponse,
        }
        setConversation(prevConversation => ({
          ...prevConversation,
          messages: [...prevConversation.messages, newBotMessage]
        }));
      } catch (error: any) {
        const offlineMessage: MessageType = {
          id: Date.now() + 1,
          type: 'received',
          time: dayjs().format('MM/DD/YY HH:mm'),
          readAt: null,
          message: `I'm sorry, but it seems I could not complete the requested prompt. Please try again later or contact our support team for assistance.`,
        };
        setConversation(prevConversation => ({
          ...prevConversation,
          messages: [...prevConversation.messages, offlineMessage]
        }));
      } finally {
        setProcessingInput(false);  // mark message as ended
      }
    },
    [conversation, selectedNeuroskill, selectedModule]
  );

  return (
    <ChatWidgetContext.Provider
      value={{
        conversation,
        setConversation,
        isOpenChat,
        setIsOpenChat,
        selectedNeuroskill,
        setSelectedNeuroskill,
        selectedModule,
        setSelectedModule,
        sentMessage,
        isProcessingInput
      }}
    >
      {children}
    </ChatWidgetContext.Provider>
  );
};

export const useChatWidgetContext = () => useContext(ChatWidgetContext);
export default HazbotWidgetProvider;
