/* @v3.3 - chequear analisis del documento que se adjunta pero no lee su contenido */
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

const LoadingText = ({ text = "Processing" }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev.length >= 3) return '';
        return prev + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-muted fs-8">
      {text}{dots}
    </div>
  );
};

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const TypingIndicator = () => (
    <>
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className=""
          style={{ animationDelay: `${i * 0.2}s` }}
        />
      ))}
        <Spinner animation="border" size="sm" />
    </>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        //finalMessage = `${messageText}\n\nArchivo adjunto: ${fileAttachment.name}`;
        /* const metadata en chatbox */
        const metadata = Object.entries(fileContent.metadata || {})
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</document>\n</documents>`;
        /* end metadata en chatbox */
        setIsProcessingFile(true);
      }

      sentMessage({
        message: messageText, // changed this line
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment, finalMessage)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
              setIsProcessingFile(false);
            }}
          />
          {isProcessingFile && <LoadingText />}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder={`Ask ${process.env.REACT_APP_NAME}...`}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>
        {isProcessingInput && <TypingIndicator />}
        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter;

/* @v3.2 - agrego spinner del bot pensando mientras responde 
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const TypingIndicator = () => (
    <>
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className=""
          style={{ animationDelay: `${i * 0.2}s` }}
        />
      ))}
        <Spinner animation="border" size="sm" />
    </>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        // archivo adjunto sin preview de contenido
        finalMessage = `${messageText}\n\nArchivo adjunto: ${fileAttachment.name}`;
        setIsProcessingFile(true);
      }

      sentMessage({
        message: finalMessage,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
      // No limpiamos fileAttachment ni fileContent aquí para mantener el estado de "procesando"
      // Se limpiarán cuando recibamos la respuesta
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
              setIsProcessingFile(false);
            }}
          />
          {isProcessingFile && (
            <div className="text-muted fs-8">Processing...</div>
          )}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Ask Hazbot..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>
        {isProcessingInput && <TypingIndicator />}
        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter; */

/* @v3.2 - agrego spinner del bot pensando mientras responde 
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
//import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const TypingIndicator = () => (
    <>
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className=""
          style={{ animationDelay: `${i * 0.2}s` }}
        />
      ))}
        <Spinner animation="border" size="sm" />
    </>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        const metadata = Object.entries(fileContent.metadata || {})
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');

        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</document>\n</documents>`;
      }

      sentMessage({
        message: finalMessage,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
      setFileContent(null);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
            }}
          />
          {isProcessingFile && (
            <div className="text-muted fs-8">Processing file...</div>
          )}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Ask Hazbot..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>
        {isProcessingInput && <TypingIndicator />}
        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter;

/* @v3.1 - agrego formatos excel pdf powerpoint word y esas mariconadas */
/*
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
//import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();
  const TypingIndicator = () => (
    <div className="typing-indicator position-absolute bottom-100 start 0 mb-2 ms-4">
      <div className="bg-light rounded-pill px-3 py-2 d-inline-flex align-items-center">
        <div className="typing-dots">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
        <span className="ms-2 text-muted fs-8">Bot está escribiendo...</span>
      </div>
      <style>{`
        .typing-dots {
          display: flex;
          gap: 4px;
        }
        .dot {
          width: 6px;
          height: 6px;
          background: #6c757d;
          border-radius: 50%;
          animation: typing 1.4s infinite;
          opacity: 0.6;
        }
        .dot:nth-child(2) { animation-delay: 0.2s; }
        .dot:nth-child(3) { animation-delay: 0.4s; }
        @keyframes typing {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-4px); }
        }
      `}</style>
    </div>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        const metadata = Object.entries(fileContent.metadata || {})
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');

        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</document>\n</documents>`;
      }

      sentMessage({
        message: finalMessage,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
      setFileContent(null);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
            }}
          />
          {isProcessingFile && (
            <div className="text-muted fs-8">Processing file...</div>
          )}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-6">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Tell me something..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>

        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter; */

/* @v3.0 - Lectura archivos adjuntos en formato texto plano */ 

/*
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState } from 'react';
import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown } from 'react-bootstrap';

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string>('');
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const readFileContent = async (file: File) => {
    try {
      const content = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.readAsText(file);
      });
      
      setFileContent(content);
      return content;
    } catch (error) {
      console.error('Error reading file:', error);
      return null;
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || fileAttachment || imageAttachments.length > 0) {
      let finalMessage = messageText;

      // incluir contenido en el mensaje (opcional esta wea)
      if (fileAttachment && fileContent) {
        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<document_content>\n${fileContent}\n</document_content>\n</document>\n</documents>`;
      }

      sentMessage({
        message: finalMessage,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
      setFileContent('');
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      await readFileContent(file);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent('');
            }}
          />
        </div>
      )}

      {imageAttachments && (
        <div
          className={classNames('d-flex gap-2', {
            'mb-2': imageAttachments.length
          })}
        >
          {imageAttachments.map((attachment, index) => (
            <ImageAttachmentPreview
              key={index}
              image={URL.createObjectURL(attachment)}
              handleClose={() => {
                setImageAttachments(
                  imageAttachments.filter((_, i) => index !== i)
                );
              }}
            />
          ))}
        </div>
      )}
      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-6">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Tell me something..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={handleKeyDown}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput}
          />
        </div>

        <div>
          <Button className="p-0">
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".csv,.txt,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter;
*/
